import { FC } from 'react';
import { useController } from 'react-hook-form';

import { SxProps, Theme } from '@mui/material';

import { TextField, TimelyTextFieldProps } from '../base';

interface RhfTextFieldProps extends TimelyTextFieldProps {
  control: any;
  name: string;
  sx?: SxProps<Theme> | undefined;
  showHelperText?: boolean;
}

const RhfTextField: FC<RhfTextFieldProps> = ({
  control,
  name,
  label,
  placeholder,
  fullWidth,
  multiline,
  rows,
  password,
  showHelperText = false,
  sx,
  ...otherProps
}) => {
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { error }
  } = useController({
    name,
    control,
    defaultValue: ''
  });

  return (
    <TextField
      label={label}
      helperText={showHelperText ? error?.message : undefined}
      placeholder={placeholder}
      error={error ? true : false}
      onChange={onChange} // send value to hook form
      onBlur={onBlur} // notify when input is touched/blur
      value={value} // input value
      name={name} // send down the input name
      inputRef={ref} // send input ref, so we can focus on input when error appear
      fullWidth={fullWidth}
      rows={rows}
      multiline={multiline}
      password={password}
      sx={sx}
      {...otherProps}
    />
  );
};

export { RhfTextField };
